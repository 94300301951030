<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [ {{ management.name }} ] - 변경 이력</v-toolbar-title
      >
    </v-app-bar>

    <v-card-subtitle>{{ standalone ? "&nbsp;" : "변경 이력" }}</v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options.sync="elementSearchForm"
        multi-sort
        class="elevation-24"
        dense
      >
        <template v-slot:item.number="{ item }">
          {{ item.metadata.revisionNumber }}
        </template>
        <template v-slot:item.timestamp="{ item }">
          {{ $moment(item.metadata.delegate.timestamp).format() }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.metadata.revisionType }}
        </template>

        <template v-slot:item.username="{ item }">
          {{ item.entity._updated.username }}
        </template>
        <template v-slot:item.address="{ item }">
          {{ item.entity._updated.address }}
        </template>

        <template v-slot:item.contents="{ item }">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                변경 내용 보기
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <pre>{{ item }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";

import Validator from "../../app.validator.js";

export default {
  name: "ManagementsRevisions",

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    management: {},

    rules: {
      version: [Validator.required("속성 이름이 필요합니다.")],
      file: [Validator.required("속성 값이 필요합니다.")],
    },

    managementsPrice: false,
    element: {},
    elements: [],

    elementSearch: {
      headers: [
        { text: "Revision", value: "number", sortable: false },
        { text: "버전 날짜", value: "timestamp", sortable: false },
        { text: "버전 타입", value: "type", sortable: false },
        { text: "Username", value: "username", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "Contents", value: "contents", sortable: false },
        // { text: "24시간 요금표", value: "prices"},
        // { text: "action", value: "action", sortable: false },
      ],
      totalElements: 0,
      loading: false,
    },
    elementSearchForm: {
      query: {},
    },
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.management = args;
      this.searchElements(true);
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    searchElements(reset) {
      this.elementDialog = false;

      if (reset === true) {
        // this.elementSearchForm.query = this.query;
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 5;
        this.elementSearchForm.sortBy = ["timestamp"];
        this.elementSearchForm.sortDesc = ["desc"];
        this.elementSearchForm.query = { management: this.management.id };
      }
      // console.log(this.$options.name, "searchElements", this.elementSearchForm);
      if (this.elementSearchForm.query.management == null) return;

      OCPP_API.managements.revisions
        .search(this.elementSearchForm)
        .then((r) => {
          console.log(this.$options.name, "searchElements", r);

          this.elements = r.data._embedded.revisions;
          this.elementSearch.totalElements = r.data.page.totalElements;
          this.elementSearch.loading = false;
        });
    },
  },
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },
};
</script>

<style></style>
