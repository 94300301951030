<template>
  <v-card>
    <v-card-title> 충전 스테이션 </v-card-title>
    <v-card-subtitle> Charging Station </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options.sync="elementSearchForm"
        multi-sort
        class="elevation-24"
      >
        <template v-slot:top>
          <!-- 
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="elementSearchForm.query.station"
                label="station"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="elementSearchForm.query.name"
                label="Name"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="resetElements(false)">
                Search
              </v-btn>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="resetElements(true)">
                Reset
              </v-btn>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="init">
                New
              </v-btn>
            </v-col>
          </v-row>
          -->
        </template>

        <template v-slot:item.path="{ item }">
          <a @click="editElement(item)">
            {{ item.uri }}
          </a>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="elementEdit" width="80%">
      <v-card>
        <v-app-bar color="deep-purple accent-4" dense>
          <v-toolbar-title>충전 스테이션 [{{ element.name }}]</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <managements-connection
            ref="managementsConnection"
            :standalone="false"
          />
        </v-card-text>

        <v-card-text>
          <managements-metadatas
            ref="managementsMetadatas"
            :standalone="false"
          />
        </v-card-text>

        <v-card-text>
          <managements-firmwares
            ref="managementsFirmwares"
            :standalone="false"
          />
        </v-card-text>

        <v-card-text>
          <managements-share ref="managementsShare" :standalone="false" />
        </v-card-text>

        <v-card-text>
          <managements-revisions
            ref="managementsRevisions"
            :standalone="false"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="elementEdit = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Authorization from "@/app/env.authorization.js";
import OCPP_API from "@/app/env.ocpp.api.js";

import ManagementsConnection from "@/app/contents/managements/ManagementsConnection.vue";
import ManagementsMetadatas from "@/app/contents/managements/ManagementsMetadatas.vue";
import ManagementsFirmwares from "@/app/contents/managements/ManagementsFirmwares.vue";
import ManagementsShare from "@/app/contents/managements/ManagementsShare.vue";
import ManagementsRevisions from "@/app/contents/managements/ManagementsRevisions.vue";

const ws = process.env.VUE_APP_OCPP_WS_SERVER;

export default {
  components: {
    ManagementsConnection,
    ManagementsMetadatas,
    ManagementsShare,
    ManagementsFirmwares,
    ManagementsRevisions,
  },

  data: () => ({
    uri: ws,
    token: null,
    element: {},
    elements: [],

    elementSearch: {
      headers: [
        { text: "ID", value: "id" },
        { text: "충전 스테이션", value: "name" },
        { text: "OCPP URL", value: "path" },
        // { text: "attributes", value: "attributes" },
      ],
      totalElements: 0,
      loading: false,
    },
    elementSearchForm: {
      query: { owner: true },
    },

    elementEdit: false,
    elementEditForm: {
      valid: false,
    },
  }),

  methods: {
    init() {
      alert("init");
    },

    resetElements(reset) {
      if (reset === true) {
        this.elementSearchForm.query = { owner: true };
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 10;
        this.elementSearchForm.sortBy = [];
        this.elementSearchForm.sortDesc = [];
      } else {
        this.searchElements();
      }
    },

    searchElements() {
      console.log("searchElements", 1, this.elementSearchForm);
      OCPP_API.managements.search(this.elementSearchForm).then((r) => {
        // console.log("searchElements", 2, r);
        this.elements = r.data._embedded.managements;
        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
        this.token = Authorization.getAuthorizationData().token;
      });
    },

    editElement(element) {
      this.element = element;
      if (this.element.attributes == null) {
        this.element.attributes = {};
      }

      this.elementEdit = true;

      setTimeout(() => {
        this.$refs.managementsMetadatas.active(element, "update");
        this.$refs.managementsConnection.active(element);
        this.$refs.managementsFirmwares.active(element);
        this.$refs.managementsShare.active(element);
        this.$refs.managementsRevisions.active(element);
      }, 300);
    },
  },
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },
  mounted() {},
};
</script>

<style></style>
