var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.standalone)?_c('v-app-bar',{attrs:{"color":"deep-purple accent-4","dense":""}},[_c('v-toolbar-title',[_vm._v("충전 스테이션 [ "+_vm._s(_vm.management.name)+" ] - 변경 이력")])],1):_vm._e(),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.standalone ? " " : "변경 이력"))]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-24",attrs:{"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"items":_vm.elements,"loading":_vm.elementSearch.loading,"headers":_vm.elementSearch.headers,"server-items-length":_vm.elementSearch.totalElements,"options":_vm.elementSearchForm,"multi-sort":"","dense":""},on:{"update:options":function($event){_vm.elementSearchForm=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata.revisionNumber)+" ")]}},{key:"item.timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.metadata.delegate.timestamp).format())+" ")]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata.revisionType)+" ")]}},{key:"item.username",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity._updated.username)+" ")]}},{key:"item.address",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity._updated.address)+" ")]}},{key:"item.contents",fn:function(ref){
      var item = ref.item;
return [_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 변경 내용 보기 ")]),_c('v-expansion-panel-content',[_c('pre',[_vm._v(_vm._s(item))])])],1)],1)]}}])})],1),(_vm.standalone)?_c('v-divider'):_vm._e(),(_vm.standalone)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.inactive(false)}}},[_vm._v(" Close ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }